import React from "react";
import "./Sidebar.css";
import { useLocation } from "react-router";
import MenuItem from "./menuItem/MenuItem";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Inventory from "/icons/all/inventory/Inventory.svg";
import Shipment from "/icons/all/shipments/Shipment.svg";
import OrderIcon from "/icons/all/orders/Orders.svg";
import Dashboard from "/icons/all/Dashboard.svg";
import ColdChain from "/icons/all/Cold Chain.svg";
import Campaign from "/icons/all/Campaign.svg";
import VaccineReport from "/icons/all/Vaccine Report.svg";
import ProductReport from "/icons/all/Product Report.svg";
import LastMile from "/icons/all/Last Mile.svg";
import LocationPin from "/icons/all/Last Mile.svg";
import AdminDashboard from "/icons/all/Admin dashboard.svg";
import ManageUsers from "/icons/all/manage user.svg";
import ManageLevels from "/icons/all/Manage levels.svg";
import ProductList from "/icons/all/product list.svg";
import Configuration from "/icons/all/Configuration.svg";
import Threshold from "/icons/all/Threshold.svg";
import GeoTracking from "/icons/all/geo-tracking.svg";
import NeedForcasting from "/icons/all/need-forcasting.svg";

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { t } = useTranslation();
  const { permissions } = useSelector((state) => state.auth);

  const AdminMenu = [
    {
      url: "/admin/dashboard",
      title: t("dashboard"),
      icon: AdminDashboard,
    },
    {
      url: "/admin/manage-users",
      title: t("manage_users"),
      icon: ManageUsers,
    },
    {
      url: "/admin/manage-organization",
      title: t("manage_levels"),
      icon: ManageLevels,
    },
    {
      url: "/admin/product-list",
      title: t("product_list"),
      icon: ProductList,
    },

    {
      url: "/admin/configuration",
      title: t("configuration"),
      icon: Configuration,
    },
    {
      url: "/admin/threshold",
      title: t("threshold"),
      icon: Threshold,
    },
  ];

  const UserMainMenu = [
    {
      url: "/user/dashboard",
      title: t("dashboard"),
      key: "OVERVIEW",
      icon: Dashboard,
    },

    {
      url: "/user/inventory",
      title: t("inventory"),
      key: "VIEW_INVENTORY",
      icon: Inventory,
    },
    {
      url: "/user/orders",
      title: t("orders"),
      key: "VIEW_ORDER",
      icon: OrderIcon,
    },
    {
      url: "/user/shipments",
      title: t("shipments"),
      key: "VIEW_SHIPMENT",
      icon: Shipment,
    },
    {
      url: "/user/arrival-report/vaccine",
      title: t("vaccine_report"),
      key: "VIEW_VAR",
      icon: VaccineReport,
    },
    {
      url: "/user/arrival-report/product",
      title: t("product_report"),
      key: "VIEW_PAR",
      icon: ProductReport,
    },
  ];

  const UserTrackingMenu = [
    {
      url: "/user/geo-tracking/tracking",
      title: t("geo_tracking"),
      key: "VIEW_CAMPAIGN",
      icon: GeoTracking,
    },
    {
      url: "/user/data-visualization",
      title: t("data_visualization"),
      key: "VIEW_CAMPAIGN",
      icon: OrderIcon,
    },
    {
      url: "/user/decision-making/graphs",
      title: t("decision_making"),
      key: "VIEW_CAMPAIGN",
      icon: OrderIcon,
    },
    {
      url: "/user/need-forecasting/draft",
      title: t("need_forecasting"),
      key: "VIEW_CAMPAIGN",
      icon: NeedForcasting,
    },
  ];

  const UserMonitoringMenu = [
    {
      url: "/user/cold-chain",
      title: t("cold_chain"),
      key: "VIEW_COLDCHAIN",
      icon: ColdChain,
    },
    {
      url: "/user/last-mile",
      title: t("last_mile"),
      key: "LAST_MILE",
      icon: LastMile,
      role: "Vaccinator",
    },

    {
      url: "/user/campaign/manage",
      title: t("campaign"),
      key: "VIEW_CAMPAIGN",
      icon: Campaign,
    },
  ];

  return (
    <React.Fragment>
      <aside className='Sidebar__wrapper'>
        <div className='Sidebar__navigation_menu'>
          {location.pathname.includes("/admin") && (
            <div className='Sidebar__menu_container menu_space'>
              <div className='menu_heading_space'>
                <p className='Sidebar__menu_heading menu_headline'>
                  {t("menu")}
                </p>
              </div>

              <ul className='Sidebar__menu_bar'>
                {AdminMenu?.map((menu, index) => {
                  if (menu?.key) {
                    if (permissions?.includes(menu?.key)) {
                      return (
                        <MenuItem
                          key={index}
                          url={menu.url}
                          icon={menu.icon}
                          title={menu.title}
                          sidebarOpen={sidebarOpen}
                          setSidebarOpen={setSidebarOpen}
                        />
                      );
                    }
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        url={menu.url}
                        icon={menu.icon}
                        title={menu.title}
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                      />
                    );
                  }
                })}
              </ul>
            </div>
          )}

          {location.pathname.includes("/user") && (
            <div className='Sidebar__menu_container menu_space'>
              <div className='menu_heading_space'>
                <p className='Sidebar__menu_heading menu_headline'>
                  {t("menu")}
                </p>
              </div>

              <ul className='Sidebar__menu_bar'>
                {UserMainMenu?.map((menu, index) => {
                  if (menu?.key) {
                    if (permissions?.includes(menu?.key)) {
                      return (
                        <MenuItem
                          key={index}
                          url={menu.url}
                          icon={menu.icon}
                          title={menu.title}
                          sidebarOpen={sidebarOpen}
                          setSidebarOpen={setSidebarOpen}
                        />
                      );
                    }
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        url={menu.url}
                        icon={menu.icon}
                        title={menu.title}
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                      />
                    );
                  }
                })}
              </ul>

              <div className='menu_heading_space'>
                <p className='Sidebar__menu_heading menu_headline'>
                  {t("tracking")}
                </p>
              </div>

              <ul className='Sidebar__menu_bar'>
                {UserTrackingMenu?.map((menu, index) => {
                  if (menu?.key) {
                    if (permissions?.includes(menu?.key)) {
                      return (
                        <MenuItem
                          key={index}
                          url={menu.url}
                          icon={menu.icon}
                          title={menu.title}
                          sidebarOpen={sidebarOpen}
                          setSidebarOpen={setSidebarOpen}
                        />
                      );
                    }
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        url={menu.url}
                        icon={menu.icon}
                        title={menu.title}
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                      />
                    );
                  }
                })}
              </ul>

              <div className='menu_heading_space'>
                <p className='Sidebar__menu_heading menu_headline'>
                  {t("monitoring")}
                </p>
              </div>

              <ul className='Sidebar__menu_bar'>
                {UserMonitoringMenu?.map((menu, index) => {
                  if (menu?.key) {
                    if (permissions?.includes(menu?.key)) {
                      return (
                        <MenuItem
                          key={index}
                          url={menu.url}
                          icon={menu.icon}
                          title={menu.title}
                          sidebarOpen={sidebarOpen}
                          setSidebarOpen={setSidebarOpen}
                        />
                      );
                    }
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        url={menu.url}
                        icon={menu.icon}
                        title={menu.title}
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                      />
                    );
                  }
                })}
              </ul>
            </div>
          )}
        </div>
        <div
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className={`Sidebar_slide_button ${
            sidebarOpen && "sidebar_shrinkable"
          }`}
        >
          <i className='fa-solid fa-chevron-left'></i>
        </div>
      </aside>
    </React.Fragment>
  );
}
