import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router";
import LoadingFallback from "../../shared/Loader/LoadingFallback";
import Home from "../../shared/home/Home";
import Login from "../../shared/connection/login/Login";
import Signup from "../../shared/connection/signup/Signup";
import SignupVerify from "../../shared/connection/signupVerify/SignupVerify";
import CreatePassword from "../../shared/connection/createPassword/CreatePassword";
import Forgot from "../../shared/connection/forgot/Forgot";
import ForgotVerify from "../../shared/connection/forgotVerify/ForgotVerify";
import ResetPassword from "../../shared/connection/resetPassword/ResetPassword";
import LoginHRIS from "../../shared/connection/loginHRIS/LoginHRIS";
import Onboard from "../../shared/connection/onboard/Onboard";
import PageLayout from "../layouts/PageLayout/Pagelayout";
import AuthLayout from "../layouts/AuthLayout/AuthLayout";
import ErrorPage from "../../shared/errorPage/ErrorPage";
import UnAuthorized from "../../shared/errorPage/UnAuthorized";
import ProtectedRoute from "./routeLayers/ProtectedRoute";
import AuthRoute from "./routeLayers/AuthRoute";
import UserDashboard from "../../users/pages/userDashboard/UserDashboard";
import PermissionRoute from "./routeLayers/PermissionRoute";

// Import all lazy-loaded components from LazyComponents
import {
  // Admin components
  AdminDashboard,
  ManageUsers,
  ManageOrganization,
  ProductList,
  Configuration,
  Threshold,

  // Inventory related components
  Inventory,
  AddInventory,
  ReviewInventory,
  InventoryRecall,
  CreateRecall,
  InventoryAdjustment,
  CreateAdjustment,
  UpdateVVM,
  ViewProduct,

  // Order related components
  Orders,
  ViewOrders,
  AddOrders,
  ReviewOrders,

  // Shipment related components
  Shipments,
  ViewShipments,
  NewShipment,
  ReviewShipments,
  AcceptShipment,
  ReverseShipment,

  // Report related components
  VaccineArrivalReport,
  CreateVAR,
  ReviewVAR,
  ViewVAR,
  CreatePAR,
  ReviewPAR,
  ViewPAR,

  // Cold chain related components
  ColdChain,
  FunctionalData,
  HistoricalData,
  AddAssets,
  AssetTransfer,
  CreateAssetTransfer,

  // Maintenance related components
  Maintenance,
  AddJobCard,

  // Beneficiary related components
  Beneficiaries,
  AddBeneficiary,

  // Visualization and tracking components
  DataVisualization,
  GeoTracking,
  TransportRoute,
  ViewTrip,

  // Campaign related components
  Campaign,
  AddCampaign,

  // Service page
  ServicePage,

  // Auto indent components
  AutoIndent,
  AutoIndentForm,
  MinMaxIndentForm,
  ConsumptionIndentForm,
  DraftIndents,

  // Decision making components
  DecisionMakingGraphs,
  DecisionMaking,
} from "../../lazy";

// Wrap component with Suspense
const SuspenseWrapper = (Component) => (
  <Suspense fallback={<LoadingFallback />}>{Component}</Suspense>
);

const PageRoutes = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthRoute>
        <Home />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: (
      <AuthRoute>
        <AuthLayout />
      </AuthRoute>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "login/hris",
        element: <LoginHRIS />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "signup/verify",
        element: <SignupVerify />,
      },
      {
        path: "signup/create-password",
        element: <CreatePassword />,
      },
      {
        path: "signup/onboarding",
        element: <Onboard />,
      },
      {
        path: "forgot",
        element: <Forgot />,
      },
      {
        path: "forgot/verify",
        element: <ForgotVerify />,
      },
      {
        path: "forgot/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <PageLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "admin/dashboard",
        element: (
          <PermissionRoute allowed={"FULL_ADMIN"}>
            {SuspenseWrapper(<AdminDashboard />)}
          </PermissionRoute>
        ),
      },
      {
        path: "admin/manage-users",
        element: (
          <PermissionRoute allowed={"FULL_ADMIN"}>
            {SuspenseWrapper(<ManageUsers />)}
          </PermissionRoute>
        ),
      },
      {
        path: "admin/manage-organization",
        element: (
          <PermissionRoute allowed={"FULL_ADMIN"}>
            {SuspenseWrapper(<ManageOrganization />)}
          </PermissionRoute>
        ),
      },
      {
        path: "admin/product-list",
        element: (
          <PermissionRoute allowed={"FULL_ADMIN"}>
            {SuspenseWrapper(<ProductList />)}
          </PermissionRoute>
        ),
      },
      {
        path: "admin/configuration",
        element: (
          <PermissionRoute allowed={"FULL_ADMIN"}>
            {SuspenseWrapper(<Configuration />)}
          </PermissionRoute>
        ),
      },
      {
        path: "admin/threshold",
        element: (
          <PermissionRoute allowed={"FULL_ADMIN"}>
            {SuspenseWrapper(<Threshold />)}
          </PermissionRoute>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <PageLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "user/dashboard",
        element: (
          <PermissionRoute allowed={"OVERVIEW"}>
            {SuspenseWrapper(<UserDashboard />)}
          </PermissionRoute>
        ),
      },

      {
        path: "user/orders",
        element: SuspenseWrapper(<Orders />),
      },
      {
        path: "user/orders/add-orders",
        element: (
          <PermissionRoute allowed={"CREATE_ORDER"}>
            {SuspenseWrapper(<AddOrders />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/orders/review-orders",
        element: (
          <PermissionRoute allowed={"CREATE_ORDER"}>
            {SuspenseWrapper(<ReviewOrders />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/orders/view-order/:id",
        element: (
          <PermissionRoute allowed={"VIEW_ORDER"}>
            {SuspenseWrapper(<ViewOrders />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/last-mile",
        element: (
          <PermissionRoute allowed={"LAST_MILE"}>
            {SuspenseWrapper(<Beneficiaries />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/beneficiary/add-beneficiary",
        element: (
          <PermissionRoute allowed={"LAST_MILE"}>
            {SuspenseWrapper(<AddBeneficiary />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/inventory",
        element: SuspenseWrapper(<Inventory />),
      },
      {
        path: "user/inventory/add-inventory",
        element: (
          <PermissionRoute allowed={"CREATE_INVENTORY"}>
            {SuspenseWrapper(<AddInventory />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/inventory/review-inventory",
        element: (
          <PermissionRoute allowed={"CREATE_INVENTORY"}>
            {SuspenseWrapper(<ReviewInventory />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/inventory/recall",
        element: (
          <PermissionRoute allowed={"CREATE_INVENTORY"}>
            {SuspenseWrapper(<InventoryRecall />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/inventory/recall/new",
        element: (
          <PermissionRoute allowed={"CREATE_INVENTORY"}>
            {SuspenseWrapper(<CreateRecall />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/inventory/adjustment/:tab",
        element: (
          <PermissionRoute allowed={"CREATE_INVENTORY"}>
            {SuspenseWrapper(<InventoryAdjustment />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/inventory/adjustment/new/:url",
        element: (
          <PermissionRoute allowed={"CREATE_INVENTORY"}>
            {SuspenseWrapper(<CreateAdjustment />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/inventory/adjustment/vvm-status",
        element: (
          <PermissionRoute allowed={"CREATE_INVENTORY"}>
            {SuspenseWrapper(<UpdateVVM />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/shipments",
        element: SuspenseWrapper(<Shipments currentShipment='outbound' />),
      },
      {
        path: "user/shipments/inbound",
        element: SuspenseWrapper(<Shipments currentShipment='inbound' />),
      },
      {
        path: "user/shipments/new-shipments",
        element: (
          <PermissionRoute allowed={"CREATE_SHIPMENT"}>
            {SuspenseWrapper(<NewShipment />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/shipments/new-shipments/:id",
        element: (
          <PermissionRoute allowed={"CREATE_SHIPMENT"}>
            {SuspenseWrapper(<NewShipment />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/shipments/review-shipments",
        element: (
          <PermissionRoute allowed={"CREATE_SHIPMENT"}>
            {SuspenseWrapper(<ReviewShipments />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/shipments/view-shipment/:id",
        element: (
          <PermissionRoute allowed={"VIEW_SHIPMENT"}>
            {SuspenseWrapper(<ViewShipments />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/shipments/reverse-shipments",
        element: (
          <PermissionRoute allowed={"CREATE_SHIPMENT"}>
            {SuspenseWrapper(<ReverseShipment />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/shipments/accept-shipments/:id",
        element: (
          <PermissionRoute allowed={"RECEIVE_SHIPMENT"}>
            {SuspenseWrapper(<AcceptShipment />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/arrival-report/vaccine",
        element: (
          <PermissionRoute allowed={"VIEW_VAR"}>
            {SuspenseWrapper(<VaccineArrivalReport currentTab='VAR' />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/arrival-report/product",
        element: (
          <PermissionRoute allowed={"VIEW_PAR"}>
            {SuspenseWrapper(<VaccineArrivalReport currentTab='PAR' />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/arrival-report/vaccine/new",
        element: (
          <PermissionRoute allowed={"CREATE_VAR"}>
            {SuspenseWrapper(<CreateVAR />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/arrival-report/vaccine/review",
        element: (
          <PermissionRoute allowed={"CREATE_VAR"}>
            {SuspenseWrapper(<ReviewVAR />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/vaccine-arrival-report/view-var/:id",
        element: (
          <PermissionRoute allowed={"VIEW_VAR"}>
            {SuspenseWrapper(<ViewVAR />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/arrival-report/product/new",
        element: (
          <PermissionRoute allowed={"CREATE_PAR"}>
            {SuspenseWrapper(<CreatePAR />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/arrival-report/product/review",
        element: (
          <PermissionRoute allowed={"CREATE_PAR"}>
            {SuspenseWrapper(<ReviewPAR />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/arrival-report/product/view/:id",

        element: (
          <PermissionRoute allowed={"VIEW_PAR"}>
            {SuspenseWrapper(<ViewPAR />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/cold-chain",
        element: (
          <PermissionRoute allowed={"VIEW_COLDCHAIN"}>
            {SuspenseWrapper(<ColdChain />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/cold-chain/add/assets",

        element: (
          <PermissionRoute allowed={"ADD_COLDCHAIN"}>
            {SuspenseWrapper(<AddAssets />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/assets-transfer",
        element: (
          <PermissionRoute allowed={"VIEW_COLDCHAIN"}>
            {SuspenseWrapper(<AssetTransfer />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/assets-transfer/new",
        element: (
          <PermissionRoute allowed={"ADD_COLDCHAIN"}>
            {SuspenseWrapper(<CreateAssetTransfer />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/view-functional-status",
        element: (
          <PermissionRoute allowed={"VIEW_COLDCHAIN"}>
            {SuspenseWrapper(<FunctionalData />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/view-historical-data/:id",
        element: (
          <PermissionRoute allowed={"VIEW_COLDCHAIN"}>
            {SuspenseWrapper(<HistoricalData />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/maintenance",
        element: (
          <PermissionRoute allowed={"VIEW_MAINTENANCE"}>
            {SuspenseWrapper(<Maintenance />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/maintenance/job",
        element: (
          <PermissionRoute allowed={"ADD_MAINTENANCE"}>
            {SuspenseWrapper(<AddJobCard />)}
          </PermissionRoute>
        ),
      },
      {
        path: "user/data-visualization/info",
        element: SuspenseWrapper(<DataVisualization />),
      },
      {
        path: "user/decision-making/graphs",
        element: SuspenseWrapper(<DecisionMaking />),
      },
      {
        path: "user/campaign/manage",
        element: SuspenseWrapper(<Campaign />),
      },
      {
        path: "user/campaign/:url",
        element: SuspenseWrapper(<AddCampaign />),
      },
      {
        path: "user/data-visualization",
        element: SuspenseWrapper(<GeoTracking />),
      },
      {
        path: "user/geo-tracking/:url",
        element: SuspenseWrapper(<TransportRoute />),
      },
      {
        path: "user/geo-tracking/history/:id",
        element: SuspenseWrapper(<ViewTrip />),
      },
      {
        path: "user/services/:url",
        element: SuspenseWrapper(<ServicePage />),
      },

      {
        path: "user/view-product",
        element: SuspenseWrapper(<ViewProduct />),
      },
      {
        path: "user/need-forecasting/:tab",
        element: SuspenseWrapper(<DraftIndents />),
      },
      {
        path: "user/auto-indent/:tab",
        element: SuspenseWrapper(<AutoIndent />),
      },
      {
        path: "user/auto-indent/auto",
        element: SuspenseWrapper(<AutoIndentForm />),
      },
      {
        path: "user/auto-indent/consumption",
        element: SuspenseWrapper(<ConsumptionIndentForm />),
      },
      {
        path: "user/auto-indent/min-max",
        element: SuspenseWrapper(<MinMaxIndentForm />),
      },
    ],
  },
  {
    path: "/unauthorized",
    element: <UnAuthorized />,
  },
]);

export default PageRoutes;
