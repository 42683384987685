import React from "react";
import { Link, useLocation } from "react-router";

export default function MenuItem({
  url,
  icon,
  title,
  sidebarOpen,
  setSidebarOpen,
}) {
  const location = useLocation();
  return (
    <React.Fragment>
      <li
        className='Sidebar__menu_element'
        onClick={() => {
          if (window.innerWidth <= 768) {
            setSidebarOpen(!sidebarOpen);
          }
        }}
      >
        <Link
          to={url}
          className={`Sidebar__menu_link ${
            location.pathname.includes(url) ? "active" : null
          }  ${sidebarOpen && "sidebar_shrinkable"}`}
        >
          {icon.includes("svg") ? (
            <img
              src={icon}
              alt=''
              id='Sidebar__menu_icon'
              className='Sidebar__icon'
            />
          ) : (
            <i
              className={`bx  ${icon} Sidebar__menu_icon Sidebar__blue_icon`}
            ></i>
          )}
          <p className='Sidebar__menu_title'>{title}</p>
        </Link>
      </li>
    </React.Fragment>
  );
}
