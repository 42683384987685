import React from "react";
import "./ErrorPage.css";
import Illustration from "/error/Ilustration.png";
import { useNavigate } from "react-router";

export default function ErrorPage() {
  const navigate = useNavigate();
  return (
    <section className='ErrorPage__section'>
      <div className='mi_home_container'>
        <div className='ErrorPage__layout'>
          <div className='ErrorPage__content'>
            <div className='ErrorPage__text'>
              <h1 className='ErrorPage__title_fs'>Oops....</h1>
              <h2 className='ErrorPage__subtitle_fs'>Something went wrong</h2>
              <p className='ErrorPage__description'>
                This page does not exist or was removed! We suggest you back to
                home.
              </p>
              <button
                className='mi_btn mi_btn_large ErrorPage__btn'
                onClick={() => navigate(-1)}
              >
                <i className='fa-solid fa-arrow-left'></i>
                Back to previous page
              </button>
              <button
                className='mi_btn mi_btn_link error_link'
                onClick={() => navigate("/")}
              >
                <i className='fa-solid fa-home'></i>
                <span> Back to Home</span>
              </button>
            </div>
            <div className='ErrorPage__image'>
              <img src={Illustration} alt='' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
