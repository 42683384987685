import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { jwtDecode } from "jwt-decode";
import { clearAuthentication } from "../../../store/slices/authSlice";

export default function AuthRoute({ children }) {
  const { theToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const userDetails = theToken && jwtDecode(theToken);
  const expirationDate = userDetails && new Date(userDetails.exp * 1000);

  // Get the current date
  const currentDate = new Date();

  useEffect(() => {
    if (theToken) {
      // Check if the token is expired
      if (currentDate > expirationDate) {
        dispatch(clearAuthentication());
      }
    }
  }, []);

  // Check if the token exists
  if (!theToken) {
    return children;
  } else {
    // Token is still valid, redirect based on role
    if (userDetails) {
      if (currentDate > expirationDate) {
        dispatch(clearAuthentication());
      } else {
        return <Navigate to='/user/dashboard' replace />;
      }
    }
  }
}
