import React from "react";

// Admin components
const AdminDashboard = React.lazy(() =>
  import("./admin/pages/adminDashboard/AdminDashboard"),
);
const ManageUsers = React.lazy(() =>
  import("./admin/pages/manageUsers/ManageUsers"),
);
const ManageOrganization = React.lazy(() =>
  import("./admin/pages/manageOrganization/ManageOrganization"),
);
const ProductList = React.lazy(() =>
  import("./admin/pages/productList/ProductList"),
);
const Configuration = React.lazy(() =>
  import("./admin/pages/configuration/Configuration"),
);
const Threshold = React.lazy(() => import("./admin/pages/threshold/Threshold"));

// Inventory related components
const Inventory = React.lazy(() => import("./users/pages/inventory/Inventory"));
const AddInventory = React.lazy(() =>
  import("./users/pages/addInventory/AddInventory"),
);
const ReviewInventory = React.lazy(() =>
  import("./users/pages/reviewInvOrders/ReviewInventory"),
);
const InventoryRecall = React.lazy(() =>
  import("./users/pages/inventoryRecall/InventoryRecall"),
);
const CreateRecall = React.lazy(() =>
  import("./users/pages/inventoryRecall/createRecall/CreateRecall"),
);
const InventoryAdjustment = React.lazy(() =>
  import("./users/pages/inventoryAdjustment/InventoryAdjustment"),
);
const CreateAdjustment = React.lazy(() =>
  import("./users/pages/createAdjustment/CreateAdjustment"),
);
const UpdateVVM = React.lazy(() => import("./users/pages/updateVVM/UpdateVVM"));
const ViewProduct = React.lazy(() =>
  import("./users/pages/viewProduct/ViewProduct"),
);

// Order related components
const Orders = React.lazy(() => import("./users/pages/orders/Orders"));
const ViewOrders = React.lazy(() =>
  import("./users/pages/viewOrders/ViewOrders"),
);
const AddOrders = React.lazy(() => import("./users/pages/addOrders/AddOrders"));
const ReviewOrders = React.lazy(() =>
  import("./users/pages/reviewInvOrders/ReviewOrders"),
);

// Shipment related components
const Shipments = React.lazy(() => import("./users/pages/shipments/Shipments"));
const ViewShipments = React.lazy(() =>
  import("./users/pages/viewShipments/ViewShipments"),
);
const NewShipment = React.lazy(() =>
  import("./users/pages/newShipment/NewShipment"),
);
const ReviewShipments = React.lazy(() =>
  import("./users/pages/reviewShipment/ReviewShipments"),
);
const AcceptShipment = React.lazy(() =>
  import("./users/pages/acceptShipment/AcceptShipment"),
);
const ReverseShipment = React.lazy(() =>
  import("./users/pages/reverseShipment/ReverseShipment"),
);

// Report related components
const VaccineArrivalReport = React.lazy(() =>
  import("./users/pages/vaccineReport/VaccineArrivalReport"),
);
const CreateVAR = React.lazy(() =>
  import("./users/pages/vaccineReport/CreateVAR"),
);
const ReviewVAR = React.lazy(() => import("./users/pages/reviewVAR/ReviewVAR"));
const ViewVAR = React.lazy(() => import("./users/pages/viewVAR/ViewVAR"));
const CreatePAR = React.lazy(() =>
  import("./users/pages/productReport/CreatePAR"),
);
const ReviewPAR = React.lazy(() => import("./users/pages/reviewPAR/ReviewPAR"));
const ViewPAR = React.lazy(() => import("./users/pages/viewPAR/ViewPAR"));

// Cold chain related components
const ColdChain = React.lazy(() => import("./users/pages/coldChain/ColdChain"));
const FunctionalData = React.lazy(() =>
  import("./users/pages/coldChain/functionalData/FunctionalData"),
);
const HistoricalData = React.lazy(() =>
  import("./users/pages/coldChain/historicalData/HistoricalData"),
);
const AddAssets = React.lazy(() => import("./users/pages/addAssets/AddAssets"));
const AssetTransfer = React.lazy(() =>
  import("./users/pages/assetTransfer/AssetTransfer"),
);
const CreateAssetTransfer = React.lazy(() =>
  import("./users/pages/createAssetTransfer/CreateAssetTransfer"),
);

// Maintenance related components
const Maintenance = React.lazy(() =>
  import("./users/pages/maintenance/Maintenance"),
);
const AddJobCard = React.lazy(() =>
  import("./users/pages/maintenance/jobCard/AddJobCard"),
);

// Beneficiary related components
const Beneficiaries = React.lazy(() =>
  import("./users/pages/beneficiary/Beneficiary"),
);
const AddBeneficiary = React.lazy(() =>
  import("./users/pages/addBeneficiary/AddBeneficiary"),
);

// Visualization and tracking components
const DataVisualization = React.lazy(() =>
  import("./users/pages/dataVisualization/DataVisualization"),
);
const GeoTracking = React.lazy(() =>
  import("./users/pages/geoTracking/GeoTracking"),
);
const TransportRoute = React.lazy(() =>
  import("./users/pages/transportRoute/TransportRoute"),
);
const ViewTrip = React.lazy(() => import("./users/pages/viewTrips/ViewTrip"));

// Campaign related components
const Campaign = React.lazy(() => import("./users/pages/campaign/Campaign"));
const AddCampaign = React.lazy(() =>
  import("./users/pages/campaign/AddCampaign"),
);

// Service page
const ServicePage = React.lazy(() =>
  import("./users/pages/servicePage/ServicePage"),
);

// Auto indent components
const AutoIndent = React.lazy(() =>
  import("./users/pages/autoIndent/AutoIndent"),
);
const AutoIndentForm = React.lazy(() =>
  import("./users/pages/autoIndent/AutoForm/AutoIndentForm"),
);
const MinMaxIndentForm = React.lazy(() =>
  import("./users/pages/autoIndent/minMaxForm/MinMaxForm"),
);
const ConsumptionIndentForm = React.lazy(() =>
  import("./users/pages/autoIndent/consumptionForm/ConsumptionForm"),
);
const DraftIndents = React.lazy(() =>
  import("./users/pages/draftIndents/DraftIndents"),
);

// Decision making components
const DecisionMaking = React.lazy(() =>
  import("./users/pages/decisionMaking/DecisionMaking"),
);
const DecisionMakingGraphs = React.lazy(() =>
  import("./users/pages/decisionMaking/DecisionMakingGraphs"),
);

export {
  // Admin components
  AdminDashboard,
  ManageUsers,
  ManageOrganization,
  ProductList,
  Configuration,
  Threshold,

  // Inventory related components
  Inventory,
  AddInventory,
  ReviewInventory,
  InventoryRecall,
  CreateRecall,
  InventoryAdjustment,
  CreateAdjustment,
  UpdateVVM,
  ViewProduct,

  // Order related components
  Orders,
  ViewOrders,
  AddOrders,
  ReviewOrders,

  // Shipment related components
  Shipments,
  ViewShipments,
  NewShipment,
  ReviewShipments,
  AcceptShipment,
  ReverseShipment,

  // Report related components
  VaccineArrivalReport,
  CreateVAR,
  ReviewVAR,
  ViewVAR,
  CreatePAR,
  ReviewPAR,
  ViewPAR,

  // Cold chain related components
  ColdChain,
  FunctionalData,
  HistoricalData,
  AddAssets,
  AssetTransfer,
  CreateAssetTransfer,

  // Maintenance related components
  Maintenance,
  AddJobCard,

  // Beneficiary related components
  Beneficiaries,
  AddBeneficiary,

  // Visualization and tracking components
  DataVisualization,
  GeoTracking,
  TransportRoute,
  ViewTrip,

  // Campaign related components
  Campaign,
  AddCampaign,

  // Service page
  ServicePage,

  // Auto indent components
  AutoIndent,
  AutoIndentForm,
  MinMaxIndentForm,
  ConsumptionIndentForm,
  DraftIndents,

  // Decision making components
  DecisionMakingGraphs,
  DecisionMaking,
};
