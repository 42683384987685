import React from "react";
import { Link, useNavigate } from "react-router";
import { useFormik, Formik } from "formik";
import PhoneInputField from "../signup/PhoneInputField";
import { Alert } from "@mui/material";

// Image Import
import LockIcon from "/icons/lock-blue.svg";

// Mui Imports
import { sendOtp } from "../../../redux/userActions";
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "../../utils/PhoneNumberInput";

export default function Forgot() {
  const Navigate = useNavigate();
  const [apiErrors, setApiErrors] = React.useState("");
  const [emailPhone, setEmailPhone] = React.useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className='Authform__container'>
        <Link to={"/login"}>
          <div className='Authform__back_button'>
            <i className='fa-solid fa-arrow-left'></i>
            <h1 className='auth_back_fs'>{t("back")}</h1>
          </div>
        </Link>
        <div className='Authform__wrapper single_column'>
          <Formik
            initialValues={{
              email: "",
              phone: "",
            }}
            validate={(values) => {
              const errors = {};
              if (values.email) {
                if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = t("valid_email_error");
                }
              }
              if (!values.email && !values.phone) {
                errors.email = t("email_phone_error");
              }
              return errors;
            }}
            onSubmit={async (values) => {
              const sendOTPBody = {
                forgotPassword: true,
                resend: false,
              };
              if (values.email.length) {
                sendOTPBody["email"] = values.email;
              } else if (values.phone.length) {
                sendOTPBody["phoneNumber"] = values.phone;
              }

              const res = await sendOtp(sendOTPBody);

              if (res.data.success == true) {
                Navigate("/forgot/verify", { state: { values: values } });
              } else {
                setApiErrors(res.data.message);
                setTimeout(() => {
                  setApiErrors("");
                }, 2000);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className='Authform__form_group' onSubmit={handleSubmit}>
                <div className='Authform__headline_space'>
                  <Link to={"/login"}>
                    <div className='Auth_Back_mobile_btn'>
                      <i className='fa-solid fa-arrow-left'></i>
                      <h1 className='auth_back_fs'>{t("back")}</h1>
                    </div>
                  </Link>
                  <h1 className='auth_heading_fs'>{t("forgot_title")}</h1>
                  <p className='auth_subheading_fs'>{t("forgot_subtitle")}</p>
                </div>
                <div className='Authform__form_body'>
                  <div className='Input__table'>
                    <div className='Input__row single_column '>
                      <div className='email__phone_switch_relative'>
                        {!emailPhone ? (
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {t("email_address")}
                            </p>
                            <input
                              type='text'
                              className='mi_custom_textfield'
                              id='email'
                              name='email'
                              placeholder={t("email_address")}
                              onChange={handleChange}
                              value={values.email}
                            />
                          </div>
                        ) : (
                          <div className='Input__column'>
                            <p className='Input__label_fs'>
                              {t("phone_number")}
                            </p>

                            <PhoneNumberInput
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.phone}
                            />
                          </div>
                        )}
                        <div
                          className='Authform__forgot_button email__phone_switch'
                          onClick={() => {
                            setEmailPhone((prev) => !prev);
                            values.phone = "";
                            values.email = "";
                          }}
                        >
                          {emailPhone ? (
                            <i className='fa-solid fa-envelope custom_icon'></i>
                          ) : (
                            <i className='fa-solid fa-phone custom_icon'></i>
                          )}
                          <p className='auth__message_fs custom_icon'>
                            {emailPhone ? t("use_email") : t("use_phone")}
                          </p>
                        </div>
                      </div>

                      {errors.email && touched.email && (
                        <span className='Input__error_fs'>{errors.email}</span>
                      )}
                    </div>
                  </div>
                </div>
                {apiErrors && <Alert severity='error'>{apiErrors}</Alert>}
                <div className='Authform__form_actions'>
                  <div className='Authform__submit_button'>
                    <button className='mi_btn mi_btn_medium mi_btn_primary'>
                      <span>{t("recover_button")}</span>
                      <i className='fa-solid fa-arrow-right'></i>
                    </button>
                  </div>

                  <div className='Authform__redirection_links'>
                    <div className='redirection_links_button'>
                      <Link to='/signup' className='redirection_links_main_fs'>
                        {t("register_redirect_msg")}{" "}
                        <span className='redirection_links_fs'>
                          {t("register")} !
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}
