import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './ChatAI.css';
import ChatImage from '/icons/chat.png';
import { useTranslation } from 'react-i18next';
import { useGetChatMessages } from '../../services/other-services/mutations';

import showdown from 'showdown';
const converter = new showdown.Converter();

const DEFAULT_CHAT = {
  role: 'assistant',
  message:
    'Hello! I’m Vaxin Bot, here to help you with anything related to the Vaccine Intelligent Network System (VaxIN). Feel free to ask me any questions. How can I assist you today?',
};

const THINKING_CHAT = {
  role: 'assistant',
  message: 'thinking ...',
};

const useClickOutside = (handler) => {
  let node = useRef();
  useEffect(() => {
    const maybeHandler = (event) => {
      if (!node.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', maybeHandler);
    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  }, [handler]);
  return node;
};

const ChatAI = () => {
  const { t } = useTranslation();
  const [chatOpen, setChatOpen] = useState(false);
  const [chatData, setChatData] = useState([DEFAULT_CHAT]);
  const [chatMessage, setChatMessage] = useState('');
  const messagesEndRef = useRef(null);

  const profileRef = useClickOutside(() => setChatOpen(false));

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatData, scrollToBottom]);

  const { mutate, isPending } = useGetChatMessages();

  const handleToggleChat = () => {
    setChatOpen((prev) => !prev);
  };

  const handleInputChange = (e) => {
    setChatMessage(e.target.value);
  };

  const handleEnterPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      submitMessage();
    }
  };

  const submitMessage = () => {
    if (!chatMessage.trim()) return;

    const userMessage = {
      role: 'user',
      message: chatMessage,
    };

    const updatedChatData = [...chatData, userMessage];
    setChatData(updatedChatData);

    const requestData = {
      message: chatMessage,
      conversationHistory: updatedChatData,
    };

    mutate(requestData, {
      onSuccess: (response) => {
        const assistantMessage = {
          role: 'assistant',
          message: response.data,
        };
        setChatData((prevChatData) => [...prevChatData, assistantMessage]);
      },
    });

    setChatMessage('');
  };

  return (
    <>
      <div className={`chat_overlay ${chatOpen ? 'active' : ''}`}></div>
      {/* {chatOpen && <div className='chat_overlay'></div>} */}
      <button
        className={`chatbot-toggler ${chatOpen ? 'show-chatbot' : ''}`}
        onClick={handleToggleChat}
      >
        {chatOpen ? (
          <span className='material-symbols-outlined'>close</span>
        ) : (
          <img className='ChatImage__img' src={ChatImage} alt='Chat' />
        )}
      </button>
      <div
        className={`chatbot ${chatOpen ? 'show-chatbot' : ''}`}
        ref={profileRef}
      >
        <header>
          <h2>{t('vaxin_chatbot')}</h2>
          {/* <button
            className='close-btn'
            onClick={handleToggleChat}
            tabIndex={0}
            aria-pressed='false'
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleToggleChat();
              }
            }}
          >
            <span className='material-symbols-outlined'>close</span>
          </button> */}
        </header>
        <ul className='chatbox'>
          {chatData.map((chatElement, index) => (
            <ChatMessage
              key={index}
              role={chatElement.role}
              content={chatElement.message}
            />
          ))}
          {isPending && (
            <ChatMessage
              role={THINKING_CHAT.role}
              content={THINKING_CHAT.message}
            />
          )}
          <div ref={messagesEndRef}></div>
        </ul>
        <div className='chat-input'>
          <textarea
            placeholder={'Ask your question...'}
            value={chatMessage}
            onChange={handleInputChange}
            onKeyDown={handleEnterPress}
            required
          ></textarea>
          <span
            id='send-btn'
            className='material-symbols-rounded'
            onClick={submitMessage}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') submitMessage();
            }}
            tabIndex='0'
          >
            send
          </span>
        </div>
      </div>
    </>
  );
};

const ChatMessage = ({ role, content }) => (
  <li className={`chat ${role === 'assistant' ? 'incoming' : 'outgoing'}`}>
    {role === 'assistant' && (
      <div className='chat__icon_wrapper'>
        <img src={ChatImage} alt='AI Assistant' />
      </div>
    )}
    <div
          className='text-base break-words leading-relaxed'
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
        ></div>
    {/* <p>{content}</p> */}
  </li>
);

ChatMessage.propTypes = {
  role: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default ChatAI;
