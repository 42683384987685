import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "../../design/index.css";
const LoadingFallback = () => {
  return (
    <div className='loader__spinner'>
      <div className='loader__item'>
        <CircularProgress />
        <p className='page__body_fs'>Loading...</p>
      </div>
    </div>
  );
};

export default LoadingFallback;
