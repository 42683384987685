import React from "react";
import { Link, useNavigate } from "react-router";
import { Formik } from "formik";
import PhoneInputField from "../signup/PhoneInputField";
import { jwtDecode } from "jwt-decode";

// Image Import
import Alert from "@mui/material/Alert";
import LockIcon from "/icons/lock-blue.svg";
import { getUserRole, login } from "../../../redux/userActions";
import { useDispatch } from "react-redux";
import { userAuthentication } from "../../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "../../utils/PhoneNumberInput";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [apiErrors, setApiErrors] = React.useState("");
  const [emailPhone, setEmailPhone] = React.useState(false);

  return (
    <div className='Authform__container'>
      <Link to={"/"}>
        <div className='Authform__back_button'>
          <i className='fa-solid fa-arrow-left'></i>
          <h1 className='auth_back_fs'>{t("back")}</h1>
        </div>
      </Link>
      <div className='Authform__wrapper single_column'>
        <Formik
          initialValues={{
            email: "",
            phone: "",
            password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (values.email) {
              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = t("valid_email_error");
              }
            }
            if (!values.email && !values.phone) {
              errors.email = t("email_phone_error");
            }
            if (!values.password) {
              errors.password = t("password_error");
            }
            return errors;
          }}
          onSubmit={async (values) => {
            setApiErrors("");
            const loginData = {
              password: values.password,
            };
            if (values.email.length) {
              loginData["email"] = values?.email;
            } else if (values.phone.length) {
              loginData["phoneNumber"] = values?.phone;
            }
            const res = await login(loginData);

            if (res.data.success) {
              const userDetails = jwtDecode(res.data.data.token);
              const userPermissions = await getUserRole(userDetails?.roleId);
              dispatch(
                userAuthentication({
                  theToken: res.data.data.token,
                  userInfo: userDetails,
                  roleInfo: userPermissions?.data?._doc,
                  permissions: userPermissions?.data?.activePermissions,
                }),
              );

              console.log(userPermissions?.data?.activePermissions);
            } else {
              setApiErrors(res.data.message);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className='Authform__form_group' onSubmit={handleSubmit}>
              <div className='Authform__headline_space'>
                <Link to={"/"}>
                  <div className='Auth_Back_mobile_btn'>
                    <i className='fa-solid fa-arrow-left'></i>
                    <h1 className='auth_back_fs'>{t("back")}</h1>
                  </div>
                </Link>
                <h1 className='auth_heading_fs'>{t("login_heading")}</h1>
                <p className='auth_subheading_fs'>{t("login_subheading")}</p>
              </div>
              <div className='Authform__form_body'>
                <div className='Input__table'>
                  <div className='Input__row single_column '>
                    <div className='email__phone_switch_relative'>
                      {!emailPhone ? (
                        <div className='Input__column'>
                          <p className='Input__label_fs'>
                            {t("email_address")}
                          </p>
                          <input
                            type='text'
                            className='mi_custom_textfield'
                            id='email'
                            name='email'
                            placeholder={t("email_address")}
                            onChange={handleChange}
                            value={values.email}
                          />
                        </div>
                      ) : (
                        <div className='Input__column'>
                          <p className='Input__label_fs'>{t("phone_number")}</p>
                          <PhoneNumberInput
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.phone}
                          />
                        </div>
                      )}
                      <div
                        className='Authform__forgot_button email__phone_switch'
                        onClick={() => {
                          setEmailPhone((prev) => !prev);
                          values.phone = "";
                          values.email = "";
                        }}
                      >
                        {emailPhone ? (
                          <i className='fa-solid fa-envelope custom_icon'></i>
                        ) : (
                          <i className='fa-solid fa-phone custom_icon'></i>
                        )}
                        <p className='auth__message_fs custom_icon'>
                          {emailPhone ? t("use_email") : t("use_phone")}
                        </p>
                      </div>
                    </div>

                    {errors.email && touched.email && (
                      <span className='Input__error_fs'>{errors.email}</span>
                    )}
                  </div>

                  <div className='Input__row single_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'> {t("password")}</p>
                      <div className='Input__password_field'>
                        <input
                          type={showPassword ? "text" : "password"}
                          className='mi_custom_textfield'
                          id='password'
                          name='password'
                          placeholder={t("password")}
                          onChange={handleChange}
                          value={values.password}
                        />

                        <div
                          className='hide_show_icon'
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <i className='fa-regular fa-eye-slash'></i>
                          ) : (
                            <i className='fa-regular fa-eye'></i>
                          )}
                        </div>
                      </div>
                      {errors.password && touched.password && (
                        <span className='Input__error_fs'>
                          {errors.password}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='Input__row single_column'>
                    <div
                      className='Authform__forgot_button'
                      onClick={() => navigate("/forgot")}
                    >
                      <img src={LockIcon} alt='LockIcon' />
                      <p className='auth__message_fs'>
                        {" "}
                        {t("forgot_password")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {apiErrors && <Alert severity='error'>{apiErrors}</Alert>}
              <div className='Authform__form_actions'>
                <div className='Authform__submit_button'>
                  <button
                    type='submit'
                    className='mi_btn mi_btn_medium mi_btn_primary'
                  >
                    <span>{t("login_button")}</span>
                    <i className='fa-solid fa-arrow-right'></i>
                  </button>
                </div>
                <div className='Authform__redirection_links'>
                  <div className='redirection_links_button'>
                    <Link
                      to='/login/hris'
                      className='redirection_links_main_fs'
                    >
                      {t("want_to_login_with_hris_account?")}{" "}
                      <span className='redirection_links_fs'>
                        {t("HRIS_login")}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className='Authform__redirection_links'>
                  <div className='redirection_links_button'>
                    <Link to='/signup' className='redirection_links_main_fs'>
                      {t("register_redirect_msg")}{" "}
                      <span className='redirection_links_fs'>
                        {t("register")}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
