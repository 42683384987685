import React from "react";
import "./ConnectionSidebar.css";

// Import
import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import { BRAND_LOGO } from "../../constant";

export default function ConnectionSidebar() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className='ConnectionSidebar__container'>
        <div className='ConnectionSidebar__header'>
          <Link to='/' className='brand_logo_link'>
            <img src={BRAND_LOGO} alt='LightLogo' />
          </Link>
          <div className='ConnectionSidebar__body'>
            <div className='Connection__Feature_list'>
              <div className='Connection__Feature_card'>
                <i className='bx bxs-check-shield'></i>
                <p className='Connection__feature_point_fs'>
                  {t("feature_point1")}
                </p>
              </div>
              <div className='Connection__Feature_card'>
                <i className='bx bxs-check-shield'></i>
                <p className='Connection__feature_point_fs'>
                  {t("feature_point2")}
                </p>
              </div>
              <div className='Connection__Feature_card'>
                <i className='bx bxs-check-shield'></i>
                <p className='Connection__feature_point_fs'>
                  {t("feature_point3")}
                </p>
              </div>
              <div className='Connection__Feature_card'>
                <i className='bx bxs-check-shield'></i>
                <p className='Connection__feature_point_fs'>
                  {t("feature_point4")}
                </p>
              </div>
              <div className='Connection__Feature_card'>
                <i className='bx bxs-check-shield'></i>
                <p className='Connection__feature_point_fs'>
                  {t("feature_point5")}
                </p>
              </div>
              <div className='Connection__Feature_card'>
                <i className='bx bxs-check-shield'></i>
                <p className='Connection__feature_point_fs'>
                  {t("feature_point6")}
                </p>
              </div>
              <div className='Connection__Feature_card'>
                <i className='bx bxs-check-shield'></i>
                <p className='Connection__feature_point_fs'>
                  {t("feature_point7")}
                </p>
              </div>
              <div className='Connection__Feature_card'>
                <i className='bx bxs-check-shield'></i>
                <p className='Connection__feature_point_fs'>
                  {t("feature_point8")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='ConnectionSidebar__footer'>
          <Link to='https://statwig.com' reloadDocument={true} target='_blank'>
            <p className='mi_small'>{t("powered_sponsor")}</p>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}
