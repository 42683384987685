import React from "react";
import "./AuthLayout.css";
import { Outlet } from "react-router";
import ConnectionSidebar from "../../../shared/connectionSidebar/ConnectionSidebar";
import Footer from "../../../shared/footer/Footer";

export default function AuthLayout() {
  return (
    <div className='AuthLayout__main_wrapper'>
      <ConnectionSidebar />
      <main className='AuthLayout__body_container'>
        <Outlet />
        <Footer />
      </main>
    </div>
  );
}
