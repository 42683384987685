import React from "react";
import { Link, useNavigate } from "react-router";

// Mui Imports
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { Alert, MenuItem, Select } from "@mui/material";
import PhoneInputField from "./PhoneInputField";
import { Formik } from "formik";
import {
  getOrganizationLevel,
  register,
  sendOtp,
} from "../../../redux/userActions";
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "../../utils/PhoneNumberInput";

export default function Signup() {
  const Navigate = useNavigate();
  const [duplicate, setDuplicate] = React.useState(false);
  const [orgLevels, setOrgLevels] = React.useState([]);

  const [apiErrors, setApiErrors] = React.useState("");

  const { t } = useTranslation();

  React.useEffect(() => {
    async function fetchData() {
      const levels = await getOrganizationLevel();
      setOrgLevels(levels?.data);
    }
    fetchData();
  }, []);

  return (
    <div className='Authform__container'>
      <Link to={"/"}>
        <div className='Authform__back_button'>
          <i className='fa-solid fa-arrow-left'></i>
          <h1 className='auth_back_fs'>{t("back")}</h1>
        </div>
      </Link>

      <div className='Authform__wrapper'>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            level: "",
            terms: "",
          }}
          validate={(values) => {
            const errors = {};
            setDuplicate("");
            if (!values.firstName) {
              errors.firstName = t("first_name_error");
            }
            if (!values.lastName) {
              errors.lastName = t("last_name_error");
            }
            if (values.email) {
              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = t("valid_email_error");
              }
            }
            if (!values.email && !values.phone) {
              setDuplicate(t("email_phone_error"));
            }
            if (!values.level) {
              errors.level = t("org_level_error");
            }
            if (!values.terms) {
              errors.terms = t("terms_conditions_error");
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);

            // Data Format
            const registerBody = {
              firstName: values.firstName,
              lastName: values.lastName,
              orgLevel: values.level,
            };
            if (values.email) {
              registerBody["email"] = values.email;
            }
            if (values.phone) {
              registerBody["phoneNumber"] = values.phone;
            }

            // Register api call
            const res = await register(registerBody);

            // api valdiation

            if (res.data.success) {
              setApiErrors("");

              // Send OTP

              const sendOTPBody = {
                forgotPassword: true,
                resend: false,
              };
              if (values.email) {
                sendOTPBody["email"] = values.email;
              }
              if (values.phone) {
                sendOTPBody["phoneNumber"] = values.phone;
              }

              const res = await sendOtp(sendOTPBody);

              if (res.data.success) {
                setApiErrors("");
                Navigate("/signup/verify", {
                  state: { values: values },
                });
              } else {
                setApiErrors(res.data.message);
              }
            } else {
              setApiErrors(res.data.message);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form className='Authform__form_group' onSubmit={handleSubmit}>
              <div className='Authform__headline_space'>
                <Link to={"/"}>
                  <div className='Auth_Back_mobile_btn'>
                    <i className='fa-solid fa-arrow-left'></i>
                    <h1 className='auth_back_fs'>{t("back")}</h1>
                  </div>
                </Link>
                <h1 className='auth_heading_fs'>{t("signup_heading")}</h1>
                <p className='auth_subheading_fs'>{t("signup_subheading")}</p>
              </div>
              <div className='Authform__form_body'>
                <div className='Input__table'>
                  <div className='Input__row two_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("first_name")} <b>*</b>{" "}
                      </p>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        className='mi_custom_textfield'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        maxLength={50}
                      />
                      {errors.firstName && touched.firstName && (
                        <span className='Input__error_fs'>
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("last_name")}
                        <b>*</b>
                      </p>
                      <input
                        type='text'
                        id='lastName'
                        name='lastName'
                        className='mi_custom_textfield'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        maxLength={50}
                      />
                      {errors.lastName &&
                        touched.lastName &&
                        errors.lastName && (
                          <span className='Input__error_fs'>
                            {errors.lastName}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className='Input__row single_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'> {t("email_address")}</p>
                      <input
                        type='text'
                        id='email'
                        name='email'
                        className='mi_custom_textfield'
                        onChange={(e) => {
                          setDuplicate(false);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                      />

                      {errors.email && (touched.root || touched.email) && (
                        <span className='Input__error_fs'>{errors.email} </span>
                      )}
                    </div>
                  </div>
                  <div className='Input__row single_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'> {t("phone_number")}</p>
                      <PhoneNumberInput
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.phone}
                      />
                    </div>
                  </div>
                  <div className='Input__row single_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("organization_level")}
                        <b>*</b>
                      </p>

                      <FormControl fullWidth>
                        <Select
                          id='level'
                          name='level'
                          // value={OrgLevel}
                          size='small'
                          className='mui_custom_input_field'
                          // onChange={handleOrgLevelChange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.level}
                        >
                          {orgLevels?.map((row, index) => (
                            <MenuItem key={index + 1} value={row.value}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {errors.level && touched.level && errors.level && (
                        <span className='Input__error_fs'>{errors.level}</span>
                      )}
                    </div>
                  </div>

                  <div className='Input__row single_column'>
                    <div className='Authform__terms_button'>
                      <Checkbox
                        name='terms'
                        sx={{ padding: 0 }}
                        checked={values?.terms != "" ? true : false}
                        // onChange={handleChange}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.terms}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p className='terms_and_condition_fs'>
                        {t("terms_privacy")}
                      </p>
                    </div>
                    {errors.terms && touched.terms && errors.terms && (
                      <span className='Input__error_fs'>{errors.terms}</span>
                    )}
                  </div>
                </div>
              </div>

              {duplicate && (touched.email || touched.phone) && (
                <span className='Input__error_fs'>{duplicate}</span>
              )}
              {apiErrors && <Alert severity='error'>{apiErrors}</Alert>}

              <div className='Authform__form_actions'>
                <div className='Authform__submit_button'>
                  <button
                    type='submit'
                    className='mi_btn mi_btn_medium mi_btn_primary'
                  >
                    <span>{t("signup_button")}</span>
                    <i className='fa-solid fa-arrow-right'></i>
                  </button>
                </div>

                <div className='Authform__redirection_links'>
                  <div className='redirection_links_button'>
                    <Link to='/login' className='redirection_links_main_fs'>
                      {t("login_redirect_msg")}{" "}
                      <span className='redirection_links_fs'>
                        {" "}
                        {t("login")}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
