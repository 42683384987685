import React from "react";
import "./ErrorPage.css";
import Illustration from "/error/permissions.svg";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { clearAuthentication } from "../../store/slices/authSlice";

export default function UnAuthorized() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const signOut = () => {
    localStorage.removeItem("theToken");
    localStorage.removeItem("user");
    localStorage.removeItem("theTokenTemp");
    localStorage.removeItem("OrdersTab");
    dispatch(clearAuthentication());
    navigate("/");
  };
  return (
    <React.Fragment>
      <section className='ErrorPage__section'>
        <div className='mi_home_container'>
          <div className='ErrorPage__layout'>
            <div className='ErrorPage__content'>
              <div className='ErrorPage__text'>
                <h1 className='ErrorPage__title_fs'>Not Allowed</h1>
                <h2 className='ErrorPage__subtitle_fs'>
                  You don't have permission to access this page
                </h2>

                <button
                  className='mi_btn mi_btn_large ErrorPage__btn'
                  onClick={signOut}
                >
                  <i className='fa-solid fa-arrow-left'></i>
                  Back to Home
                </button>
              </div>
              <div className='ErrorPage__image'>
                <img src={Illustration} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
