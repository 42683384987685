import "./Header.css";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router";

import SearchIcon from "/icons/all/Search.svg";
import SettingsIcon from "/icons/all/Settings.svg";
import IssueIcon from "/icons/all/Issue.svg";
import UserProfileIcon from "/icons/all/User Profile.svg";
import NotificationsIcon from "/icons/all/Notifications.svg";
import SwitchAdminIcon from "/icons/all/switchAdmin.svg";
import LanguageSwitchIcon from "/icons/all/languagechange.svg";
import SignOutIcon from "/icons/all/signout.svg";
import BanglaFlag from "/icons/flags/Bangla.png";
import EnglishFlag from "/icons/flags/English.webp";

import MOH from "/branding/MOH logo.png";
import { BRAND_LOGO } from "../../constant";
import { API_URL } from "../../config";
import {
  changeUserLocations,
  getUserRole,
  logout,
} from "../../redux/userActions";
import { getUserLocationInfo } from "../../redux/orderActions";
import { getUserLocations } from "../../redux/inventoryActions";
import { useGetUserInfo } from "../../services/user-services/queries";
import {
  clearAuthentication,
  userAuthentication,
} from "../../store/slices/authSlice";

import PermissionRoute from "../../web/routes/routeLayers/PermissionRoute";
import NotificationDetails from "../../users/components/notificationDetails/NotificationDetails";
import { Tooltip } from "@mui/material";

const useClickOutside = (handler) => {
  let node = useRef();
  useEffect(() => {
    const maybeHandler = (event) => {
      if (!node.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  }, [handler]);
  return node;
};

export default function Header({ setSidebarOpen }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: userInfo } = useGetUserInfo();
  const imageUrl = userInfo?.photoId
    ? `${API_URL.imagesUrl}/${userInfo.photoId}`
    : UserProfileIcon;

  const [searchCapture, setSearchCapture] = useState("");
  const [unReadNotification, setUnReadNotification] = useState(true);
  const [profileDropDownOpen, setProfileDropDownOpen] = useState(false);
  const [notificationDropDownOpen, setNotificationDropDownOpen] =
    useState(false);
  const [locationDropDownOpen, setLocationDropDownOpen] = useState(false);
  const [userLocations, setUserLocations] = useState([]);
  const [userLocationInfo, setUserLocationInfo] = useState();
  const [locationUpdated, setLocationUpdated] = useState(false);

  const profileRef = useClickOutside(() => setProfileDropDownOpen(false));
  const notificationRef = useClickOutside(() =>
    setNotificationDropDownOpen(false),
  );
  const locationRef = useClickOutside(() => setLocationDropDownOpen(false));

  useEffect(() => {
    const fetchUserLocations = async () => {
      const results = await getUserLocations();
      setUserLocations(results?.data?.locations || []);
    };
    fetchUserLocations();
  }, []);

  useEffect(() => {
    const fetchLocationInfo = async () => {
      const result = await getUserLocationInfo();
      setUserLocationInfo(result.data);
    };
    fetchLocationInfo();
  }, [locationUpdated]);

  const handleSignOut = async () => {
    await logout();
    localStorage.clear();
    dispatch(clearAuthentication());
    navigate("/");
  };

  const changeLanguage = (language) => {
    setProfileDropDownOpen(false);
    toast.success(
      language === "en"
        ? "Language has been changed to English"
        : "ভাষা পরিবর্তন করে বাংলা করা হয়েছে",
    );
    i18n.changeLanguage(language);
  };

  const handleLocationChange = async (locationId) => {
    const res = await changeUserLocations({ locationId });
    setLocationDropDownOpen(false);

    if (res.success) {
      const userPermissions = await getUserRole(res.data?.roleId);
      dispatch(
        userAuthentication({
          userInfo: res.data,
          theToken: res.data.token,
          roleInfo: userPermissions?.data?._doc,
          permissions: userPermissions?.data?.activePermissions,
        }),
      );
      toast.success("Current Location Changed");
      setLocationUpdated((prev) => !prev);
      window.location.reload();
    } else {
      toast.error("Failed to change location");
    }
  };

  const ImageWithFallback = ({ src, fallbackSrc, alt }) => (
    <img
      src={src}
      alt={alt}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = fallbackSrc;
      }}
    />
  );

  const renderDropdown = (isOpen, ref, content) =>
    isOpen && (
      <div className='Dropdown' ref={ref}>
        {content}
      </div>
    );

  return (
    <header className='Header__main_container'>
      <nav className='Header__navbar_wrapper'>
        <div
          className='mobile_menu_icon'
          onClick={() => setSidebarOpen((prev) => !prev)}
        >
          <i className='fa-solid fa-bars'></i>
        </div>
        <Link
          to={
            location.pathname.includes("/admin")
              ? "/admin/dashboard"
              : "/user/dashboard"
          }
        >
          <figure className='Header__brand_logo'>
            <img src={BRAND_LOGO} alt='website logo' />
            <div className='Header__brand_support'>
              <img src={MOH} alt='website logo' />
            </div>
          </figure>
        </Link>

        <ul className='Header__menu_list'>
          {/* <PermissionRoute allowed='ALL_SEARCH' type='actions'>
            <li className='Header_search_btn'>
              <article className={`header_search ${searchCapture && 'active'}`}>
                <img src={SearchIcon} className='Search__icon' />
                <input
                  type='text'
                  placeholder={t('search')}
                  className='search'
                  value={searchCapture}
                  onChange={(e) => setSearchCapture(e.target.value)}
                />
              </article>
            </li>
          </PermissionRoute> */}

          <li className='Header__menu_item' ref={locationRef}>
            <div className='user__location'>
              <div
                className='Header__location_wrapper'
                onClick={() => setLocationDropDownOpen(!locationDropDownOpen)}
              >
                <i className='fa-solid fa-location-dot'></i>
                <div className='loc_text'>
                  {userLocationInfo?.name?.substring(0, 20) || ""}
                </div>
                <i className='fa-solid fa-caret-down'></i>
              </div>
              {renderDropdown(
                locationDropDownOpen,
                locationRef,
                <div
                  className={`user__location_dropdown ${
                    locationDropDownOpen && "active"
                  }`}
                >
                  {userLocations.map((level, index) => (
                    <div
                      className='user__location_dropdown_card'
                      key={index}
                      onClick={() => handleLocationChange(level._id)}
                    >
                      <i className='fa-solid fa-location-dot'></i>
                      <p className='page__note_fs'>{level?.name}</p>
                    </div>
                  ))}
                </div>,
              )}
            </div>
          </li>

          <li className='Header__menu_item' ref={notificationRef}>
            <div className='Header__notification_wrapper'>
              <Tooltip title={"Notifications"} arrow>
                <div
                  className='Header_notification_icon'
                  onClick={() =>
                    setNotificationDropDownOpen(!notificationDropDownOpen)
                  }
                >
                  <img
                    src={NotificationsIcon}
                    alt='Notifications'
                    style={{ height: "22px" }}
                    className='white_icon'
                  />
                  <span
                    className={`notification_active_dot ${
                      unReadNotification && "active"
                    }`}
                  ></span>
                </div>
              </Tooltip>
              {renderDropdown(
                notificationDropDownOpen,
                notificationRef,
                <div
                  className={`Header__notification_dropdown ${
                    notificationDropDownOpen && "active"
                  }`}
                >
                  <div className='UserDashboard__inv_summary'>
                    <div className='UserDashboard__summary_card_header'>
                      <h1 className='page__body_heading_fs mi_white'>
                        {t("news_notifications")}
                      </h1>
                    </div>
                    <div className='Notification__body_scroll'>
                      <NotificationDetails
                        notificationBtn={notificationDropDownOpen}
                        setNotificationDropDownOpen={
                          setNotificationDropDownOpen
                        }
                      />
                    </div>
                  </div>
                </div>,
              )}
            </div>
          </li>

          <li className='Header__menu_item' ref={notificationRef}>
            <div className='Header__notification_wrapper'>
              <Tooltip title={"Raise Issue"} arrow>
                <div
                  className='Header_notification_icon'
                  onClick={() => navigate("/user/services/support")}
                >
                  <i className='fa-solid fa-circle-question'></i>
                </div>
              </Tooltip>
            </div>
          </li>

          <li className='Header__menu_item' ref={profileRef}>
            <div className='Header__profile_wrapper'>
              <div
                className='Header__profile_space'
                onClick={() => setProfileDropDownOpen(!profileDropDownOpen)}
              >
                <div className='Header__profile_picture'>
                  <ImageWithFallback
                    src={imageUrl}
                    fallbackSrc={UserProfileIcon}
                    alt='Profile'
                  />
                </div>
              </div>
              {renderDropdown(
                profileDropDownOpen,
                profileRef,
                <div
                  className={`Header__profile_dropdown ${
                    profileDropDownOpen && "active"
                  }`}
                >
                  <div className='Profile_dropdown_list'>
                    <div className='Profile_dropdown_header'>
                      <div className='dropdown_header_user'>
                        <div className='Header__profile_space_inner'>
                          <div className='Header__profile_picture_inner'>
                            <ImageWithFallback
                              src={imageUrl}
                              fallbackSrc={UserProfileIcon}
                              alt='Profile'
                            />
                          </div>
                        </div>
                        <div className='dropdown__header_info'>
                          <div className='user_name_and_role'>
                            <h1 className='User_name_fs'>{`${
                              userInfo?.firstName || ""
                            } ${userInfo?.lastName || ""}`}</h1>
                            <div className='Role_ico'>
                              {t(
                                location.pathname.includes("/admin")
                                  ? "admin"
                                  : "user",
                              )}
                            </div>
                          </div>
                          <p className='org_name_fs'>
                            {userInfo?.roleId?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='Profile_dropdown_body'>
                      <PermissionRoute allowed='FULL_ADMIN' type='actions'>
                        <div
                          className='dropdown__menu'
                          onClick={() => {
                            navigate(
                              location.pathname.includes("/admin")
                                ? "/user/dashboard"
                                : "/admin/dashboard",
                            );
                            setProfileDropDownOpen(false);
                          }}
                        >
                          <img
                            src={SwitchAdminIcon}
                            alt='Switch Role'
                            className='Search__icon'
                          />
                          <p className='dropdown__menu_link'>{`${t(
                            "switch_to",
                          )} ${t(
                            location.pathname.includes("/admin")
                              ? "user"
                              : "admin",
                          )}`}</p>
                        </div>
                      </PermissionRoute>
                      <div
                        className='dropdown__menu'
                        onClick={() => {
                          navigate("/user/services/profile");
                          setProfileDropDownOpen(false);
                        }}
                      >
                        <img
                          src={UserProfileIcon}
                          alt='Profile'
                          className='Search__icon'
                        />
                        <p className='dropdown__menu_link'>{t("profile")}</p>
                      </div>
                      <div
                        className='dropdown__menu'
                        onClick={() => {
                          navigate("/user/services/settings");
                          setProfileDropDownOpen(false);
                        }}
                      >
                        <img
                          src={SettingsIcon}
                          alt='Settings'
                          className='Search__icon'
                        />
                        <p className='dropdown__menu_link'>{t("settings")}</p>
                      </div>
                      <div
                        className='dropdown__menu'
                        onClick={() => {
                          navigate("/user/services/support");
                          setProfileDropDownOpen(false);
                        }}
                      >
                        <img
                          src={IssueIcon}
                          alt='Support'
                          className='Search__icon'
                        />
                        <p className='dropdown__menu_link'>{t("support")}</p>
                      </div>
                      <div
                        className='dropdown__menu language__menu'
                        onClick={() =>
                          changeLanguage(i18n.language === "en" ? "bn" : "en")
                        }
                      >
                        <div className='lang__info'>
                          <img
                            src={LanguageSwitchIcon}
                            alt='Change Language'
                            className='Search__icon'
                          />
                          <p className='dropdown__menu_link'>
                            {t("change_language")}
                          </p>
                        </div>

                        {i18n.language === "en" ? (
                          <div className='flag-circle'>
                            <img
                              src={BanglaFlag}
                              alt='Bangladesh flag'
                              className='flag-circle-image'
                            />
                          </div>
                        ) : (
                          <div className='flag-circle'>
                            <img
                              src={EnglishFlag}
                              alt='English Flag'
                              className='flag-circle-image'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='Profile_dropdown_footer'>
                      <div className='dropdown__menu' onClick={handleSignOut}>
                        <img
                          src={SignOutIcon}
                          alt='Sign Out'
                          className='Search__icon'
                        />
                        <p className='dropdown__menu_link'>{t("sign_out")}</p>
                      </div>
                    </div>
                  </div>
                </div>,
              )}
            </div>
          </li>
        </ul>
      </nav>

      {(profileDropDownOpen || notificationDropDownOpen) && (
        <div
          className={`Background__overlay ${
            (profileDropDownOpen || notificationDropDownOpen) && "active"
          }`}
        ></div>
      )}
    </header>
  );
}
