import React from "react";
import "./HomeShowcase.css";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

export default function HomeShowcase() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <section className='HomeShowcase__section'>
        <div className='mi_home_container'>
          <div className='HomeShowcase__page_layout'>
            <div className='HomeShowcase__content'>
              <div
                className='HomeShowcase__title'
                style={{ cursor: "default" }}
              >
                <h1 className='HomeShowcase__title_fs'>
                  {t("showcase_title")}
                </h1>
                <h1 className='HomeShowcase__subtitle_fs'>
                  {t("showcase_subtitle")}
                </h1>
              </div>
              <div className='HomeShowcase__actions'>
                <Link to={"/signup"}>
                  <button className='mi_btn mi_btn_medium mi_btn_white'>
                    {t("create_account")}
                  </button>
                </Link>
                <Link to={"/login"}>
                  <button className='mi_btn mi_btn_medium mi_btn_primary'>
                    {t("sign_in")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
