import React, { useEffect, useRef, useState } from "react";
import "./HomeHeader.css";
import { Link } from "react-router";

import EnglishFlag from "/icons/flags/English.webp";
import BanglaFlag from "/icons/flags/Bangla.png";

// Import
import { useTranslation } from "react-i18next";
import { BRAND_LOGO } from "../../../constant";

const useClickOutside = (handler) => {
  let languageNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!languageNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return languageNode;
};

export default function HomeHeader() {
  const { t, i18n } = useTranslation();
  const [LanguageOpen, setLanguageOpen] = useState(false);

  let languageNode = useClickOutside(() => {
    setLanguageOpen(false);
  });

  const changeLanguage = (lng) => {
    setLanguageOpen(false);
    i18n.changeLanguage(lng);
  };

  return (
    <React.Fragment>
      <header id='header' className='HomeHeader__header_wrap'>
        <div className='mi_home_container'>
          <nav className='HomeHeader__navbar'>
            <article className='HomeHeader__navbar_left'>
              <Link
                to='/'
                className='brand_logo_link'
                style={{ cursor: "default" }}
              >
                <img src={BRAND_LOGO} alt='LightLogo' />
              </Link>
            </article>

            <article className='HomeHeader__navbar_right'>
              <ul className='HomeHeader__menu_list'>
                <li
                  className={`HomeHeader__menu_item language_options ${
                    LanguageOpen && "active"
                  }`}
                  ref={languageNode}
                >
                  <section
                    className={`language_dropdown ${LanguageOpen && "active"}`}
                    onClick={() => setLanguageOpen(!LanguageOpen)}
                  >
                    {i18n.language === "en" ? (
                      <>
                        <div className='flag-circle'>
                          <img
                            src={EnglishFlag}
                            alt='flags'
                            className='flag-circle-image'
                          />
                        </div>

                        <p className='nav__menu_link'>{t("english")}</p>
                      </>
                    ) : (
                      <>
                        <div className='flag-circle'>
                          <img
                            src={BanglaFlag}
                            alt='flags'
                            className='flag-circle-image'
                          />
                        </div>

                        <p className='nav__menu_link'>{t("bangla")}</p>
                      </>
                    )}
                  </section>

                  <section className='language-list'>
                    <article
                      className='language-card'
                      onClick={() => {
                        changeLanguage("en");
                      }}
                    >
                      <div className='flag-circle'>
                        <img
                          src={EnglishFlag}
                          alt='flags'
                          className='flag-circle-image'
                        />
                      </div>

                      <p className='vl-body'>{t("english")}</p>
                    </article>
                    <article
                      className='language-card no-border'
                      onClick={() => {
                        changeLanguage("bn");
                      }}
                    >
                      <div className='flag-circle'>
                        <img
                          src={BanglaFlag}
                          alt='flags'
                          className='flag-circle-image'
                        />
                      </div>

                      <p className='vl-body'>{t("bangla")}</p>
                    </article>
                  </section>
                </li>
              </ul>
              <ul className='HomeHeader__menu_list'>
                <Link to={"/login/hris"}>
                  <button className='mi_btn mi_btn_link'>
                    {t("HRIS_login")}
                  </button>
                </Link>
                <div className='HomeHeader__login_actions'>
                  <Link to={"/signup"}>
                    <button className='mi_btn mi_btn_medium mi_btn_secondary bg_green'>
                      {t("create_account")}
                    </button>
                  </Link>
                  <Link to={"/login"}>
                    <button className='mi_btn mi_btn_medium mi_btn_primary'>
                      {t("login")}
                    </button>
                  </Link>
                </div>
              </ul>
            </article>
          </nav>
        </div>
      </header>
    </React.Fragment>
  );
}
